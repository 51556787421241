<template>
  <vx-card no-shadow>
    <div v-if="isMobile" class="flex items-center mb-4">
      <span class="user-settings__title-inner">{{ $t('vue.general') }}</span>
    </div>

    <!-- Img Row -->
    <div class="flex flex-wrap items-left mb-base">
      <div class="vx-row w-full">
        <div class="vx-col sm:w-full lg:w-1/12 mb-2 mr-5" :class="{ 'pl-0': isMobile }">
          <croppa
            class="upload-img shadow-md"
            :canvas-color="'default'"
            ref="myPhoto"
            :width="70"
            :height="70"
            style="left: 20px; top: 5px"
            :placeholder-font-size="14"
            :placeholder="$t('vue.clickHere')"
            :show-remove-button="false"
            :image-border-radius="80"
            prevent-white-space
            initial-size="contain"
            :disable="false"
            accept=".jpeg,.jpg,.gif,.png"
            @file-type-mismatch="onFileTypeMismatch"
            :file-size-limit="2048000"
            @file-size-exceed="onFileSizeExceed"
            @new-image-drawn="onNewImageDrawn"
            @image-remove="onImageRemoved"
            @file-choose="onFileChoose"
          >
            <Avatar
              style="position: absolute; top: 0; left: 0"
              size="70px"
              fontSize="28px"
              :photoURL="activeUserInfo && activeUserInfo.photoURL"
              :name="activeUserInfo && activeUserInfo.displayName"
            />
          </croppa>
        </div>
        <div class="vx-col sm:w-full lg:w-10/12 mb-2 text-left" :class="{ 'w-full': isMobile }">
          <vs-button class="upload-photo mr-4 sm:mb-0 mb-2" @click="selectImage()" :class="{ 'ml-0': isMobile }">{{ uploadPhotoButtonText }}</vs-button>
          <vs-button type="border" color="danger" class="upload-photo mr-4 mb-2" :class="{ 'ml-0': isMobile }" v-if="hasPhoto" @click="removePhoto()">{{
            $t('vue.deletePhoto')
          }}</vs-button>
        </div>
      </div>
      <div class="vx-row w-full mt-5">
        <div class="vx-col w-full mb-2">
          <p class="upload-photo text-sm mt-2" :class="{ 'ml-0': isMobile }">{{ $t('vue.fileTypeNotSupportedMessage') }}</p>
        </div>
      </div>
    </div>

    <!-- Info -->
    <vs-input class="w-full mb-base" :label-placeholder="$t('inputs.displayName')" v-model="displayName"></vs-input>
    <vs-input class="w-full" :label-placeholder="$t('inputs.firstName')" v-model="firstname" name="firstname" v-validate="'required'"></vs-input>
    <div class="text-danger text-sm mb-base">{{ errors.first('firstname') }}</div>
    <vs-input class="w-full" :label-placeholder="$t('inputs.lastName')" v-model="lastname" name="lastname" v-validate="'required'"></vs-input>
    <div class="text-danger text-sm mb-base">{{ errors.first('lastname') }}</div>
    <vs-input class="w-full" :label-placeholder="$t('inputs.email')" name="email" disabled v-model="email" v-validate="'required|validateEmail'"></vs-input>
    <div class="text-danger text-sm mb-base">{{ errors.first('email') }}</div>
    <vs-input class="w-full" :label-placeholder="$t('inputs.mobile')" name="mobile" v-validate="'validateMobile'" v-model="mobile" />
    <div class="text-danger text-sm">{{ errors.first('mobile') }}</div>
    <vs-input class="w-full my-base" :label-placeholder="$t('inputs.company')" v-model="companyName" :disabled="true"></vs-input>

    <div v-show="!emailVerified && !sentEmailVerification">
      <vx-card
        v-if="activeUserInfo && !activeUserInfo.emailVerified && !this.activeUserInfo.provider"
        :title="$t('vue.yourAccountIsNotVerfied')"
        title-color="#fff"
        content-color="#fff"
        card-background="primary"
        class="mb-6"
      >
        <p class="mb-3">{{ $t('vue.registrationThankYouMessage2') }}</p>
        <p class="mb-3">{{ $t('vue.registrationThankYouMessage3') }}</p>
        <p class="mb-3">{{ $t('vue.registrationThankYouMessage4') }}</p>
        <vs-button v-if="timeoutResendConfirmationEmail > 0" color="warning" type="filled" disabled @click="sendConfirmationEmail">{{
          `${$t('vue.sendConfirmationEmailAgain')} (${timeoutResendConfirmationEmail / 1000})`
        }}</vs-button>
        <vs-button v-else color="warning" type="filled" :disabled="timeoutResendConfirmationEmail > 0" @click="sendConfirmationEmail">{{
          `${$t('vue.sendConfirmationEmailAgain')}`
        }}</vs-button>
      </vx-card>
    </div>

    <!-- Save & Reset Button -->
    <div class="flex flex-wrap items-center justify-end">
      <vs-button class="ml-auto mt-2" :disabled="errors.items.length > 0" @click.prevent="saveUserGeneralInfo()">{{ $t('vue.saveChanges') }}</vs-button>
      <!-- <vs-button class="ml-4 mt-2" type="border" color="warning" @click.prevent="reset()">{{ $t('vue.reset') }}</vs-button> -->
    </div>
  </vx-card>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

import Avatar from '@/components/Avatar'

export default {
  components: {
    Avatar
  },

  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      displayName: '',
      firstname: '',
      lastname: '',
      email: '',
      mobile: '',
      emailVerified: false,
      sentEmailVerification: false,
      photoURL: '/user.png',
      name: this.$store.state.AppActiveUser.displayName,
      companyName: '',
      myPhoto: {},
      uploadTask: false,
      text: '',
      userInfo: {},
      uploadPhotoButton: 'vue.uploadPhoto',
      isFileSelected: false,
      hasPhoto: false,
      timeoutResendConfirmationEmail: 30000,
      resendConfirmationEmailInterval: null
    }
  },
  watch: {
    mobile() {
      let mobile = this.mobile
      mobile = mobile && mobile.replace(/[^\d]/g, '').startsWith('00') ? `+${mobile.replace(/[^\d]/g, '').slice(2)}` : mobile
      if (mobile) {
        mobile = `+${mobile.replace(/[^\d]/g, '')}`
      }
      this.mobile = mobile
    },
    activeUserInfo() {
      if (this.activeUserInfo && this.activeUserInfo.firstname) {
        this.firstname = this.activeUserInfo.firstname
      }
      if (this.activeUserInfo && this.activeUserInfo.lastname) {
        this.lastname = this.activeUserInfo.lastname
      }
      if (this.activeUserInfo && this.activeUserInfo.mobile) {
        this.mobile = this.activeUserInfo.mobile
      }
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    initialPhotoURL() {
      return this.activeUserInfo.photoURL
    },
    uploadPhotoButtonText() {
      return this.$i18n.t(this.uploadPhotoButton)
    }
  },
  async mounted() {
    this.$vs.loading()

    if (this.$store.state.AppActiveUser.photoURL && this.$store.state.AppActiveUser.photoURL.length > 0) {
      this.photoURL = this.$store.state.AppActiveUser.photoURL
    }
    if (this.photoURL !== null && this.photoURL !== '/user.png') {
      this.hasPhoto = true
    }
    await this.getUserInfo()

    setTimeout(() => this.$vs.loading.close(), 100)

    this.setConfirmationEmailInterval()
  },
  methods: {
    setConfirmationEmailInterval() {
      const vm = this
      vm.resendConfirmationEmailInterval = setInterval(() => {
        if (vm.timeoutResendConfirmationEmail > 0) {
          vm.timeoutResendConfirmationEmail = vm.timeoutResendConfirmationEmail - 1000
        } else {
          clearInterval(vm.resendConfirmationEmailInterval)
        }
      }, 1000)
    },
    async sendConfirmationEmail() {
      this.$vs.loading()
      this.timeoutResendConfirmationEmail = 30000
      this.setConfirmationEmailInterval()
      const sendEmailVerificationEmail = this.$functions.httpsCallable('sendEmailVerificationEmail')
      const result = await sendEmailVerificationEmail({ uid: this.activeUserInfo.uid })
      if (result.data && result.data.status === true) {
        this.$vs.notify({
          title: this.$i18n.t('vue.success'),
          text: this.$i18n.t('vue.sentConfirmationEmail'),
          color: 'green'
        })
      } else {
        this.$vs.notify({
          title: this.$i18n.t('vue.error'),
          text: this.$i18n.t('vue.couldNotSendConfirmationEmail'),
          color: 'red'
        })
      }
      this.$vs.loading.close()
    },
    // reset() {
    //   const userInfo = this.userInfo
    //   if (userInfo) {
    //     this.displayName = userInfo.displayName
    //     this.firstname = userInfo.firstname
    //     this.lastname = userInfo.lastname
    //     this.email = userInfo.email
    //     this.emailVerified = userInfo.emailVerified
    //     this.mobile = userInfo.mobile
    //   }
    //   setTimeout(() => this.errors.clear(), 50)
    // },
    async getUserInfo() {
      const currentUser = firebase.auth().currentUser
      const userInfo = this.activeUserInfo
      if (userInfo) {
        this.displayName = userInfo.displayName
        this.firstname = userInfo.firstname
        this.lastname = userInfo.lastname
        this.email = userInfo.email
        this.emailVerified = currentUser && currentUser.emailVerified ? currentUser.emailVerified : false
        this.mobile = userInfo.mobile
      }
      this.userInfo = { ...userInfo }

      if (userInfo.company && userInfo.company.trim().length > 0) {
        const companyRef = await this.$db.collection('company').doc(userInfo.company).get()
        if (companyRef) {
          this.companyName = companyRef.data().name
        }
      }
    },
    async saveUserGeneralInfo() {
      const vm = this

      this.$vs.loading()

      const user = {
        displayName: this.displayName,
        firstname: this.firstname,
        lastname: this.lastname
      }
      if (this.mobile) {
        user.mobile = this.mobile
      }
      firebase.auth().languageCode = this.$i18n.locale
      try {
        const currentUser = firebase.auth().currentUser
        const userRef = await this.$db.collection('users').doc(currentUser.uid)
        await userRef.set(user, { merge: true })

        this.$store.commit('UPDATE_USER_INFO', {
          displayName: this.displayName,
          firstname: this.firstname,
          lastname: this.lastname,
          mobile: this.mobile,
          uid: currentUser.uid,
          closeAnimation: vm.$vs.loading.close
        })
        this.userInfo = user
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.success'),
          text: this.$i18n.t('vue.changesSavedSuccessfully'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success'
        })
      } catch (error) {
        if (error.code === 'auth/email-already-in-use') {
          this.$vs.notify({
            time: 8800,
            title: this.$i18n.t('vue.error'),
            text: this.$i18n.t('vue.emailAlreadyInUse'),
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        } else {
          this.$vs.notify({
            time: 8800,
            title: this.$i18n.t('vue.error'),
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
        this.$vs.loading.close()
      }
    },
    onFileChoose() {
      this.isFileSelected = true
    },
    selectImage() {
      this.$refs.myPhoto.chooseFile()
    },
    onNewImageDrawn() {
      if (this.isFileSelected) {
        this.uploadCroppedImage()
      }
    },
    onImageRemoved() {
      this.uploadPhotoButton = 'vue.uploadPhoto'
    },
    async removePhoto() {
      const vm = this
      vm.$vs.loading()
      const storageService = firebase.storage()
      const currentUser = firebase.auth().currentUser
      if (this.photoURL !== undefined && this.photoURL !== null && this.photoURL !== '/user.png' && this.photoURL.length > 0) {
        // Use the storage service the delete the file and url
        try {
          await storageService.refFromURL(vm.photoURL).delete()

          vm.photoURL = null
          const userRef = await vm.$db.collection('users').doc(currentUser.uid)
          await userRef.set({ photoURL: null }, { merge: true })
          vm.$vs.notify({
            time: 8800,
            title: vm.$i18n.t('vue.removed'),
            text: vm.$i18n.t('vue.photoRemovedSuccessfully'),
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'warning'
          })
          vm.$store.commit('UPDATE_USER_INFO', {
            photoURL: '/user.png',
            closeAnimation: vm.$vs.loading.close
          })
          this.hasPhoto = false
          this.photoURL = '/user.png'
        } catch (err) {
          let message = err.message
          if (err.code === 'storage/object-not-found') {
            message = vm.$i18n.t('vue.objectDoesNotExists')
          }
          vm.$vs.notify({
            time: 8800,
            title: vm.$i18n.t('vue.error'),
            text: message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      }
      setTimeout(() => {
        this.$vs.loading.close()
      }, 100)
    },
    onFileTypeMismatch() {
      const vm = this
      vm.isFileSelected = false
      this.$vs.notify({
        time: 8800,
        title: vm.$i18n.t('vue.fileTypeNotSupported'),
        text: vm.$i18n.t('vue.fileTypeNotSupportedMessage'),
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    },
    onFileSizeExceed() {
      const vm = this
      vm.isFileSelected = false
      this.$vs.notify({
        time: 8800,
        title: vm.$i18n.t('vue.fileSizeLimitExceeded'),
        text: vm.$i18n.t('vue.maximumSize'),
        iconPack: 'feather',
        icon: 'icon-alert-circle',
        color: 'danger'
      })
    },
    async uploadCroppedImage() {
      const vm = this
      vm.$vs.loading()
      vm.isFileSelected = false
      try {
        vm.$refs.myPhoto.generateBlob(
          async (blob) => {
            const storageService = firebase.storage()
            const storageRef = storageService.ref()

            const file = blob
            const currentUser = firebase.auth().currentUser

            const metadata = {
              contentType: 'image/png',
              cacheControl: 'max-age=604800, public'
            }

            let uploadTask = null
            uploadTask = storageRef.child(`images/users/${currentUser.uid}`).put(file, metadata)
            this.uploadTask = true
            uploadTask.on(
              firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
              (snapshot) => {
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                this.progressUpload = progress
              },
              function (error) {
                // A full list of error codes is available at
                // https://firebase.google.com/docs/storage/web/handle-errors
                switch (error.code) {
                  case 'storage/unauthorized':
                    // User doesn't have permission to access the object
                    vm.text = 'fields.storage/unauthorized'
                    break
                  case 'storage/canceled':
                    // User canceled the upload
                    vm.text = 'fields.storage/canceled'
                    break
                  case 'storage/unknown':
                    // Unknown error occurred, inspect error.serverResponse
                    vm.text = 'fields.storage/unknown'
                    break
                }

                vm.$vs.notify({
                  time: 8800,
                  title: vm.$i18n.t('vue.error'),
                  text: error.message,
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'danger'
                })
                vm.uploadTask = false
              },
              async () => {
                // Upload completed successfully, now we can get the download URL
                this.uploadTask = false
                const downloadURL = await uploadTask.snapshot.ref.getDownloadURL()
                const userRef = await this.$db.collection('users').doc(currentUser.uid)
                userRef.set({ photoURL: downloadURL }, { merge: true })

                this.photoURL = downloadURL
                this.$vs.notify({
                  time: 8800,
                  title: vm.$i18n.t('vue.success'),
                  text: vm.$i18n.t('vue.photoSavedSuccessfully'),
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'success'
                })

                this.$store.commit('UPDATE_USER_INFO', {
                  photoURL: downloadURL,
                  closeAnimation: vm.$vs.loading.close
                })
                if (this.photoURL !== null) {
                  this.hasPhoto = true
                }
              }
            )
          },
          'image/png',
          0.8
        ) // 80% compressed jpeg file
      } catch (err) {
        this.$vs.notify({
          time: 8800,
          title: vm.$i18n.t('vue.error'),
          text: err.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.upload {
  &-photo {
    margin-left: 15px;
    margin-top: 15px;
  }
  &-img {
    overflow: hidden;
    border-radius: 50%;
  }
}
</style>
