var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vx-card",
    { attrs: { "no-shadow": "" } },
    [
      _vm.isMobile
        ? _c("div", { staticClass: "flex items-center mb-4" }, [
            _c("span", { staticClass: "user-settings__title-inner" }, [
              _vm._v(_vm._s(_vm.$t("vue.general"))),
            ]),
          ])
        : _vm._e(),
      _c("div", { staticClass: "flex flex-wrap items-left mb-base" }, [
        _c("div", { staticClass: "vx-row w-full" }, [
          _c(
            "div",
            {
              staticClass: "vx-col sm:w-full lg:w-1/12 mb-2 mr-5",
              class: { "pl-0": _vm.isMobile },
            },
            [
              _c(
                "croppa",
                {
                  ref: "myPhoto",
                  staticClass: "upload-img shadow-md",
                  staticStyle: { left: "20px", top: "5px" },
                  attrs: {
                    "canvas-color": "default",
                    width: 70,
                    height: 70,
                    "placeholder-font-size": 14,
                    placeholder: _vm.$t("vue.clickHere"),
                    "show-remove-button": false,
                    "image-border-radius": 80,
                    "prevent-white-space": "",
                    "initial-size": "contain",
                    disable: false,
                    accept: ".jpeg,.jpg,.gif,.png",
                    "file-size-limit": 2048000,
                  },
                  on: {
                    "file-type-mismatch": _vm.onFileTypeMismatch,
                    "file-size-exceed": _vm.onFileSizeExceed,
                    "new-image-drawn": _vm.onNewImageDrawn,
                    "image-remove": _vm.onImageRemoved,
                    "file-choose": _vm.onFileChoose,
                  },
                },
                [
                  _c("Avatar", {
                    staticStyle: { position: "absolute", top: "0", left: "0" },
                    attrs: {
                      size: "70px",
                      fontSize: "28px",
                      photoURL:
                        _vm.activeUserInfo && _vm.activeUserInfo.photoURL,
                      name:
                        _vm.activeUserInfo && _vm.activeUserInfo.displayName,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "vx-col sm:w-full lg:w-10/12 mb-2 text-left",
              class: { "w-full": _vm.isMobile },
            },
            [
              _c(
                "vs-button",
                {
                  staticClass: "upload-photo mr-4 sm:mb-0 mb-2",
                  class: { "ml-0": _vm.isMobile },
                  on: {
                    click: function ($event) {
                      return _vm.selectImage()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.uploadPhotoButtonText))]
              ),
              _vm.hasPhoto
                ? _c(
                    "vs-button",
                    {
                      staticClass: "upload-photo mr-4 mb-2",
                      class: { "ml-0": _vm.isMobile },
                      attrs: { type: "border", color: "danger" },
                      on: {
                        click: function ($event) {
                          return _vm.removePhoto()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("vue.deletePhoto")))]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "vx-row w-full mt-5" }, [
          _c("div", { staticClass: "vx-col w-full mb-2" }, [
            _c(
              "p",
              {
                staticClass: "upload-photo text-sm mt-2",
                class: { "ml-0": _vm.isMobile },
              },
              [_vm._v(_vm._s(_vm.$t("vue.fileTypeNotSupportedMessage")))]
            ),
          ]),
        ]),
      ]),
      _c("vs-input", {
        staticClass: "w-full mb-base",
        attrs: { "label-placeholder": _vm.$t("inputs.displayName") },
        model: {
          value: _vm.displayName,
          callback: function ($$v) {
            _vm.displayName = $$v
          },
          expression: "displayName",
        },
      }),
      _c("vs-input", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate",
            value: "required",
            expression: "'required'",
          },
        ],
        staticClass: "w-full",
        attrs: {
          "label-placeholder": _vm.$t("inputs.firstName"),
          name: "firstname",
        },
        model: {
          value: _vm.firstname,
          callback: function ($$v) {
            _vm.firstname = $$v
          },
          expression: "firstname",
        },
      }),
      _c("div", { staticClass: "text-danger text-sm mb-base" }, [
        _vm._v(_vm._s(_vm.errors.first("firstname"))),
      ]),
      _c("vs-input", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate",
            value: "required",
            expression: "'required'",
          },
        ],
        staticClass: "w-full",
        attrs: {
          "label-placeholder": _vm.$t("inputs.lastName"),
          name: "lastname",
        },
        model: {
          value: _vm.lastname,
          callback: function ($$v) {
            _vm.lastname = $$v
          },
          expression: "lastname",
        },
      }),
      _c("div", { staticClass: "text-danger text-sm mb-base" }, [
        _vm._v(_vm._s(_vm.errors.first("lastname"))),
      ]),
      _c("vs-input", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate",
            value: "required|validateEmail",
            expression: "'required|validateEmail'",
          },
        ],
        staticClass: "w-full",
        attrs: {
          "label-placeholder": _vm.$t("inputs.email"),
          name: "email",
          disabled: "",
        },
        model: {
          value: _vm.email,
          callback: function ($$v) {
            _vm.email = $$v
          },
          expression: "email",
        },
      }),
      _c("div", { staticClass: "text-danger text-sm mb-base" }, [
        _vm._v(_vm._s(_vm.errors.first("email"))),
      ]),
      _c("vs-input", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate",
            value: "validateMobile",
            expression: "'validateMobile'",
          },
        ],
        staticClass: "w-full",
        attrs: { "label-placeholder": _vm.$t("inputs.mobile"), name: "mobile" },
        model: {
          value: _vm.mobile,
          callback: function ($$v) {
            _vm.mobile = $$v
          },
          expression: "mobile",
        },
      }),
      _c("div", { staticClass: "text-danger text-sm" }, [
        _vm._v(_vm._s(_vm.errors.first("mobile"))),
      ]),
      _c("vs-input", {
        staticClass: "w-full my-base",
        attrs: {
          "label-placeholder": _vm.$t("inputs.company"),
          disabled: true,
        },
        model: {
          value: _vm.companyName,
          callback: function ($$v) {
            _vm.companyName = $$v
          },
          expression: "companyName",
        },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.emailVerified && !_vm.sentEmailVerification,
              expression: "!emailVerified && !sentEmailVerification",
            },
          ],
        },
        [
          _vm.activeUserInfo &&
          !_vm.activeUserInfo.emailVerified &&
          !this.activeUserInfo.provider
            ? _c(
                "vx-card",
                {
                  staticClass: "mb-6",
                  attrs: {
                    title: _vm.$t("vue.yourAccountIsNotVerfied"),
                    "title-color": "#fff",
                    "content-color": "#fff",
                    "card-background": "primary",
                  },
                },
                [
                  _c("p", { staticClass: "mb-3" }, [
                    _vm._v(_vm._s(_vm.$t("vue.registrationThankYouMessage2"))),
                  ]),
                  _c("p", { staticClass: "mb-3" }, [
                    _vm._v(_vm._s(_vm.$t("vue.registrationThankYouMessage3"))),
                  ]),
                  _c("p", { staticClass: "mb-3" }, [
                    _vm._v(_vm._s(_vm.$t("vue.registrationThankYouMessage4"))),
                  ]),
                  _vm.timeoutResendConfirmationEmail > 0
                    ? _c(
                        "vs-button",
                        {
                          attrs: {
                            color: "warning",
                            type: "filled",
                            disabled: "",
                          },
                          on: { click: _vm.sendConfirmationEmail },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("vue.sendConfirmationEmailAgain") +
                                " (" +
                                _vm.timeoutResendConfirmationEmail / 1000 +
                                ")"
                            )
                          ),
                        ]
                      )
                    : _c(
                        "vs-button",
                        {
                          attrs: {
                            color: "warning",
                            type: "filled",
                            disabled: _vm.timeoutResendConfirmationEmail > 0,
                          },
                          on: { click: _vm.sendConfirmationEmail },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              "" + _vm.$t("vue.sendConfirmationEmailAgain")
                            )
                          ),
                        ]
                      ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "flex flex-wrap items-center justify-end" },
        [
          _c(
            "vs-button",
            {
              staticClass: "ml-auto mt-2",
              attrs: { disabled: _vm.errors.items.length > 0 },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.saveUserGeneralInfo()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("vue.saveChanges")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }